
import { getALL, getTopAll } from '@/api/cloudmarket/objectType'
import ElementUI from 'element-ui'

export const GetAllList = async (ParentId, itemType, list, that) => {
  const res = await getALL(ParentId, itemType)
  if (res.status !== 200) return ElementUI.Message.error('获取失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  list = res.data.data
  list.forEach(async item => {
    // 添加属性
    that.$set(item, 'children', [])
    const res1 = await getALL(item.typeId, item.itemType)
    if (res1.status !== 200) return ElementUI.Message.error('获取失败')
    if (res1.data.status !== 200) return ElementUI.Message.error(res.data.message)

    item.children = res1.data.data
    // res1.data.data.forEach(item1 => {
    //   item.children.push({ typeId: item1.typeId, typeName: item1.typeName })
    // })
  })
  return list
}

export const getTopAllType = async () => {
  const res = await getTopAll()
  if (res.status !== 200) return ElementUI.Message.error('获取失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data
}
