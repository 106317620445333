<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="type">
            <div style="display: inline-block" class="divSpan">商品分类：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.type"
                :options="OneLabelIds"
                :optionKey="ParentIdkeys"
                Splaceholder="请选择"
                @clear="getList"
                @input="getList"
              ></ELselect>
            </div>
          </el-form-item>
          <el-form-item prop="relationId">
            <div style="display: inline-block" class="divSpan">
              商品联系方式：
            </div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.relationId"
                :options="ContactIds"
                :optionKey="ContactIdskeys"
                Splaceholder="请选择"
                @clear="getList"
                @input="getList"
              ></ELselect>
            </div>
          </el-form-item>
          <el-form-item prop="name">
            <div style="display: inline-block" class="divSpan">商品名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.name"
                clearable
                @clear="getList"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="name1">
            <div style="display: inline-block" class="divSpan">企业名称：</div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.name1"
                clearable
                @clear="getList"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ModelList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="name"
            label="商品名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="version"
            label="版本号"
            min-width="80px"
          ></el-table-column>
          <el-table-column
            prop="detailV"
            label="版本描述"
            min-width="200px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="company"
            label="企业名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="userId"
            label="用户ID"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="联系手机号"
            min-width="120px"
          ></el-table-column>
          <el-table-column
            prop="relationName"
            label="商品联系方式"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="createTimeB"
            label="上架时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="上架状态" min-width="100px">
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="scope.row.onSale === 1 ? 'success' : 'danger'"
                >{{ scope.row.onSale === 1 ? "已上架" : "已下架" }}</el-tag
              >
              <el-tag
                size="mini"
                :type="scope.row.onSaleV === 1 ? 'success' : 'danger'"
                >{{
                  scope.row.id
                    ? scope.row.onSaleV === 1
                      ? "版本已启用"
                      : "版本已禁用"
                    : "无版本"
                }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column label="操作" min-width="200px" fixed="right">
            <template slot-scope="scope">
              <el-button
                style="margin-top: 20px"
                :type="scope.row.onSale === 1 ? 'danger' : 'success'"
                size="mini"
                @click="DownOut(scope.row)"
                >{{ scope.row.onSale === 1 ? "下架" : "上架" }}
              </el-button>
              <el-button
                style="margin-top: 20px"
                :type="scope.row.onSaleV === 1 ? 'danger' : 'success'"
                size="mini"
                @click="DownOut1(scope.row)"
                v-show="scope.row.id"
                >{{ scope.row.onSaleV === 1 ? "版本禁用" : "版本启用" }}
              </el-button>
              <el-button
                style="margin-top: 20px"
                type="primary"
                size="mini"
                @click="viewContact(scope.row)"
                >联系方式
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 查看对话框-->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑商品联系方式"
      :visible.sync="viewDialogVisible"
      width="30%"
      @close="viewDialogClosed"
    >
      <el-form
        :model="viewForm"
        ref="viewFormRef"
        label-width="120px"
        :rules="viewFormRules"
      >
        <el-form-item label="商品名称：" prop="name">
          <el-input v-model.trim="viewForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="商品联系方式：" prop="contactPhone">
          <ELselect
            v-model="viewForm.contactPhone"
            :options="ContactIds"
            :optionKey="ContactIdskeys"
            Splaceholder="请选择"
          ></ELselect>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="viewDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editContact">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getObjectBaseList, getTemplateStateID, getVersionStateID, updateContact } from '@/api/cloudmarket/objectBase'
import { disableDia } from '@/libs/confirmDialog'
import { getTopAllType } from '@/components/myself/apiList'
import ELselect from '@/components/myself/el_select'
import { queryProductRelationListInfo } from '@/libs/commonApi'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        name: '',
        name1: '',
        type: '',
        relationId: '',
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      ModelList: [],

      // 查看按钮
      viewDialogVisible: false,
      viewForm: {
        name: '',
        contactPhone: '',
        id: ''
      },
      viewFormRules: {
        contactPhone: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
      },

      disableVlue: '',
      OneLabelIds: [],
      ParentIdkeys: {
        value: 'typeId',
        label: 'typeName',
        label2: ''
      },
      ContactIds: [],
      ContactIdskeys: {
        value: 'id',
        label: 'name',
        label2: ''
      },
    }
  },
  mounted () {
    this.getOneLevelType()
    this.getContactIds()
  },
  methods: {
    async getContactIds () {
      this.ContactIds = await queryProductRelationListInfo()
    },
    async getOneLevelType () {
      this.OneLabelIds = await getTopAllType()
      this.getList()
    },
    async getList () {
      let quiese = { type: this.queryinfo.type, name: this.queryinfo.name, company: this.queryinfo.name1, relationId: this.queryinfo.relationId }
      const res = await getObjectBaseList(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ModelList = res.data.data.list
      this.total = res.data.data.total
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    async viewContact (para) {
      this.viewForm.name = para.name
      this.viewForm.id = para.productId
      this.viewForm.contactPhone = para.relationId
      this.viewDialogVisible = true
    },
    viewDialogClosed () {
      this.$refs.viewFormRef.resetFields()
    },
    editContact () {
      this.$refs.viewFormRef.validate(async valid => {
        if (valid) {
          const res = await updateContact(this.viewForm.id, this.viewForm.contactPhone)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.viewDialogVisible = false
          this.getList()
        }
      })
    },

    async DownOut (para) {
      const res = await getTemplateStateID(para.productId)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.getList()
      if (para.onSale === 0) {
        this.$message.success('产品上架成功')
      } else {
        this.$message.warning('产品已下架')
      }
    },

    async changeState (para, cause) {
      const res = await getVersionStateID(para.id, cause)
      // console.log('77777', res)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.getList()
      if (para.onSaleV === 0) {
        this.$message.success('版本启用成功')
      } else {
        this.$message.warning('版本已禁用')
      }
    },
    async DownOut1 (para) {
      this.disableVlue = ''
      if (para.onSaleV !== 0) {
        {
          this.disableVlue = await disableDia('请输入禁用原因', this)
          if (this.disableVlue) {
            this.changeState(para, this.disableVlue)
          }
        }
      } else {
        this.changeState(para, this.disableVlue)
      }


    },
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}
/deep/ .el-form-item__content {
  margin-right: 30px;
}
</style>