import axios from '@/libs/api.request'

export const addNews = (quiese) => {
  let uri = '/news/add'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const NewsList = (pageNum, pageSize, quiese) => {
  let uri = '/news/list/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}


export const editNews = (quiese) => {
  let uri = '/news/edit'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const setNews = (id, op) => {
  let uri = '/news/setnews/' + id + '/' + op
  return axios.request({
    url: uri,
    method: 'post',
    data: { id: id, op: op }
  })
}

export const setShowing = (mode, quiese) => {
  let uri = '/news/setshowing/' + mode
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

export const getShowing = (mode) => {
  let uri = '/news/getshowing/' + mode
  return axios.request({
    url: uri,
    method: 'get'
  })
}


export const getDetail = (id) => {
  let uri = '/news/detail/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}


export const getNext = (id) => {
  let uri = '/news/next/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}

export const getPrev = (id) => {
  let uri = '/news/prev/' + id
  return axios.request({
    url: uri,
    method: 'get'
  })
}