import { setNews, getShowing, getDetail, setShowing, getPrev, getNext, NewsList } from '@/api/News.js'
import { queryAddress, queryLogistics } from '@/api/Address.js'
import { queryProductRelationList } from '@/api/cloudmarket/objectBase.js'

import axios from "axios"
import ElementUI from 'element-ui'

export const setNewInfo = async (id, op) => {
  // op——操作方式：0-下架新闻，1-上架新闻，2-删除新闻
  const res = await setNews(id, op)
  if (res.status !== 200) return ElementUI.Message.error('操作失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return true
}

export const getShowingInfo = async (mode) => {
  // mode——展示方式：1-轮播，2-推荐
  const res = await getShowing(mode)
  if (res.status !== 200) return ElementUI.Message.error('操作失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data
}


export const setShowingInfo = async (mode, quiese) => {
  // mode——展示方式：1-轮播，2-推荐
  const res = await setShowing(mode, quiese)
  if (res.status !== 200) return ElementUI.Message.error('操作失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return true
}


export const getDetailInfo = async (id) => {
  const res = await getDetail(id)
  if (res.status !== 200) return ElementUI.Message.error('操作失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data
}


// 在线预览url内容
export const getUrlContent = async (url) => {
  // const res = await axios.request({
  //   url: url,
  //   method: 'get',
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Access-Control-Allow-Credentials': true,
  //   }
  // })
  // return res.data
  // return marked(res.data)

  return await axios.get(url).then((res) => {
    return res.data
  })
}


export const searchContent = async (item) => {
  let urlContent = await getUrlContent(item.url)
  // console.log(urlContent)
  return { urlContent }
}
export const getUrlContentList = (data, type = 1) => {
  // type:1 数组格式，其他: 对象格式
  if (type == 1) {
    return data
  } else {
    return searchContent(data)
  }
}


export const getNextInfo = async (id) => {
  const res = await getNext(id)
  if (res.status !== 200) return ElementUI.Message.error('操作失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data ? res.data.data : {}
}
export const getPrevInfo = async (id) => {
  const res = await getPrev(id)
  if (res.status !== 200) return ElementUI.Message.error('操作失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data ? res.data.data : {}
}


export const NewsListInfo = async (pageNum, pageSize, quiese) => {
  const res = await NewsList(pageNum, pageSize, quiese)
  if (res.status !== 200) return ElementUI.Message.error('获取失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data
}


export const queryAddressInfo = async (tradeNo) => {
  const res = await queryAddress(tradeNo)
  if (res.status !== 200) return ElementUI.Message.error('获取失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data
}

export const queryLogisticsInfo = async (tradeNo) => {
  const res = await queryLogistics(tradeNo)
  if (res.status !== 200) return ElementUI.Message.error('获取失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data
}


export const queryProductRelationListInfo = async () => {
  const res = await queryProductRelationList()
  if (res.status !== 200) return ElementUI.Message.error('获取失败')
  if (res.data.status !== 200) return ElementUI.Message.error(res.data.message)
  return res.data.data
}
