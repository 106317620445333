import axios from '@/libs/api.request'

// // 获取所有模板/组件类型的parentId
// export const getALLParentId = (itemType) => {
//   let uri = '/objectType/allParentId/'
//   return axios.request({
//     url: uri,
//     method: 'get',
//        params: {
// itemType: itemType
//     }
//   })
// }

// // 获取模板/组件类型总数
// export const getALLCount = (itemType) => {
//   let uri = '/objectType/count'
//   return axios.request({
//     url: uri,
//     method: 'get',
//        params: {
// itemType: itemType
//     }
//   })
// }

// 获取所有顶级类型

export const getTopAll = () => {
  let uri = '/objectType/getTopAll'
  return axios.request({
    url: uri,
    method: 'get'
  })
}


// 获取全部
export const getALL = (parentId, itemType) => {
  let uri = '/objectType/getAll/' + parentId
  return axios.request({
    url: uri,
    method: 'get',
    params: {
      itemType: itemType
    }
  })
}

// 根据可选参数获取模板/组件类型列表 (一级和子类共用)
export const getObjectTypeList = (pageNum, pageSize, quiese) => {
  let uri = '/objectType/name/' + pageNum + '/' + pageSize
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}


// // 获取模板/组件类型列表
// export const getObjectTypePage = (pageNum, pageSize) => {
//   let uri = '/objectType/page/' + pageNum + '/' + pageSize
//   return axios.request({
//     url: uri,
//     method: 'get'
//   })
// }

// // 删除模板类型
// export const DeleteTemplateType = (typeId) => {
//   let uri = '/objectType/templateDelete/' + typeId
//   return axios.request({
//     url: uri,
//     method: 'get'
//   })
// }


// 批量删除子类模板类型
export const DeleteTemplateTypeSons = (typeIds) => {
  let uri = '/objectType/templateDeleteIdsSon'
  return axios.request({
    url: uri,
    method: 'post',
    data: typeIds
  })
}

// 添加一级组件/模板类型
export const TemplateInsert = (quiese) => {
  let uri = '/objectType/templateInsert'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}


// 添加子分类组件/模板类型
export const TemplateInsertSon = (quiese) => {
  let uri = '/objectType/templateInsertSon'
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

// 更新组件/模板类型
export const TemplateUpdate = (quiese) => {
  let uri = '/objectType/templateUpdate/' + quiese.typeId
  return axios.request({
    url: uri,
    method: 'post',
    data: quiese
  })
}

// 根据typeId获取模板/组件类型
export const getTypeID = (typeId) => {
  let uri = '/objectType/' + typeId
  return axios.request({
    url: uri,
    method: 'get'
  })
}

// 批量删除一级模板类型
export const DeleteTemplateTypes = (typeIds) => {
  let uri = '/objectType/templateDeleteIds'
  return axios.request({
    url: uri,
    method: 'post',
    data: typeIds
  })
}